require([
    'jquery',
    'domReady!'
], function ($) {
    //'use strict';


    // style forms
    $('body').find('.column--main form:not(".rendered-form")').each(function () {

        // Custom selects
        $(this).find('select')
            .wrap('<div class="select-wrapper"></div>');
        $(this).find('.select-wrapper')
            .append('<span class="icon"><i class="far fa-chevron-down"></i></span>');


        // Custom checkboxes
        $(this).find('input[type="checkbox"]').each(function () {
            $(this).addClass('visually-hidden')
                .next('label')
                .addClass('label')
                .wrapInner('<span class="txt"></span>')
                .prepend('<span class="icon icon--check fa-stack"><i class="far fa-square-full fa-stack-2x icon--check__box"></i><i class="far fa-check fa-stack-1x icon--check__checkmark"></i></span>')
                .andSelf()
                .wrapAll('<div class="checkbox-wrapper"></div>');
        });

    });

    var header = $(".header");
    var pw = $(".page-wrapper");
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll > 210) {
            header.addClass("header--sticky");
            pw.css("padding-top","114px");
        } else {
            header.removeClass("header--sticky");
            pw.css("padding-top","0");
        }
    });

});
